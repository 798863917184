
import React from "react"
//import { Link } from "gatsby"
import Layout from "../components/Layout"
//import MailDatenschutz from "../components/MailDatenschutz"
import Mailto from 'reactv16-mailto';
import { Jumbotron,  Container, Row, Col } from "react-bootstrap"
import { Helmet } from 'react-helmet'


import Xen_logo from "../images/Xen_logo.svg"

import "../styles/Globals.scss"


export default function Home() {
  return (
    <Layout>
      <Helmet defer={false}>
        <title defer={false}>Xen virtueller Server | WH Serverpark</title>
      </Helmet>
      <Jumbotron fluid>
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }}>
              <h1>Virtuelle Server</h1>
              <p>
                Auf unserem Hardware-Server-Cluster betreiben wir für Sie Ihren eigenen virtuellen Server.
              </p>
              <p>
                Dabei berücksichtigen wir insbesondere die vollredundante Auslegung der Hardware-Komponenten (Server, Switch, Stromversorgung, Netzwerkanbindung). Damit kann eine beliebige Komponente der eingesetzten Hardware ausfallen ohne den Betrieb einzuschränken, wobei manche Komponenten (Festplatte, Switch, Stromversorgung, Netzwerkanbindung) ausfallen können ohne den Betrieb zu unterbrechen während andere Komponenten (Server, CPU, RAM, Mainboard) beim Ausfall einen Neustart des darauf laufenden virtuellen Servers auf einer anderen Hardware zur Folge haben. Dadurch bieten wir Ihnen den Betrieb eines virtuellen Servers mit maximaler Verfügbarkeit.
              </p>
              <p>
                <Mailto className="btn btn-primary" email='info@wh-serverpark.com' obfuscate={true} headers={{ subject: 'Virtuelle Server' }}>Kontakt aufnehmen</Mailto>
              </p>
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 2 }} className="text-md-right">
              <img src={Xen_logo} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <Container flex>
        <Row>
          <Col >
            <p>
              So profitieren Sie von der Flexibilität der Virtualisierung:<br /></p>
            <ul >
              <li><strong>Hardwareunabhängigkeit</strong>: Wir aktualisieren und erweitern die Hardware während der Laufzeit Ihres virtuellen Servers ohne dabei den Betrieb Ihres virtuellen Servers zu unterbrechen. Somit können wir Ihnen in der Zukunft mehr Resourcen (RAM, CPU und Festplattenplatz) für Ihren virtuellen Server bereitstellen als wir heute maximal anbieten.</li>
              <li><strong>Zugesicherte Resourcen</strong>: Die eingesetzte Virtualisierungs-Lösung stellt sicher, dass Sie jederzeit mindestens die dem virtuellen Server zugewiesenen Resourcen (RAM, CPU und Festplattenplatz) zur Verfügung haben.</li>
              <li><strong>Anpassung der Resourcen</strong>: Ohne Unterbrechung kann der zugewiesene Festplattenplatz vergrößert, die Anzahl der verfügbaren CPUs verringert und der verfügbare RAM verringert werden. Für mehr CPU-Leistung oder mehr RAM wird nur ein Neustart des virtuellen Servers erforderlich. Um den Festplattenplatz zu reduzieren muss Ihr virtuelle Server ausgeschalten werden, deswegen ist es vorteilhaft nur den momentan benötigten Festplattenplatz dem virtuellen Server zuzuweisen und den restlichen Festplattenplatz für das Snapshot-Backup zu verwenden.</li></ul>
            <p>
              Weitere Leistungen unserer virtuellen Server:
            </p>
            <ul >
              <li><strong>Snapshot-Backup</strong>: Der eingekaufte aber noch nicht zugwiesene Festplattenplatz kann als Speicherplatz für Backups genutzt werden. Diese sind über das Verzeichnis /backup/ direkt innerhalb des virtuellen Servers erreichbar.</li>
              <li><strong>Security-Updates</strong>: Tägliche Überprüfung auf Sicherheitsupdates des Betriebssystems. Diese werden nach manueller Überprüfung innerhalb von zwei Arbeitstagen eingespielt.</li>
              <li><strong>Failure-Monitoring</strong>: Bestimmte Dienste (ping, ssh, http) und Eigenschaften (belegter Hauptspeicher, freier Festplattenplatz) Ihres virtuellen Servers werden regelmäßig überprüft und bei Ausfall bzw. Unter-/Überschreitung definierter Grenzwerte mitgeteilt.</li>
              <li><strong>Performance-Monitoring</strong>: Die beim Failure-Monitoring gesammelten Daten werden in einer Datenbank (rrd) gespeichert und können grafisch dargestellt werden. Damit behalten Sie den Überblick über den Resourcen-Verbrauch ihres Servers auch über längere Zeiträume hinweg.</li>
              <li><strong>Kundenbereich</strong>: In Ihrem Kundenbereich können Sie Ihren Server jederzeit Neustarten oder uns ein Support Ticket senden.</li></ul>
            <p>
              Gerne beantworten wir Ihre Hosting Anfragen direkt in einem persönlichen Gespräch oder per <Mailto email='info@wh-serverpark.com' obfuscate={true} headers={{ subject: 'Virtuelle Server' }}>E-Mail</Mailto>.
            </p>
          </Col>
        </Row>

      </Container>
    </Layout>
  )
}
